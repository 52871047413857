import {mapGetters, mapMutations} from "vuex";


export default {
  name: "order-info",
  data() {
    return {
      percentDiscount: 0,
      sumDiscount: 0,
      totalPrice: 0,
      newPrice: 0,
      newPriceSum: 0,
      beauticianDiscount: 0
    }
  },
  methods: {
    ...mapMutations({
      setTotalSum: 'basket/SET_TOTAL_PRICE',
      setPercentDiscount: 'basket/SET_PERCENT_DISCOUNT',
    })
  },
  computed: {
    ...mapGetters({
      showPopupAddBasket: 'basket/showPopupAddBasket',
      order: 'basket/basket',
      discount: 'basket/discount',
      certificateResponse: 'order/certificateStatus',
      getTotalSum: 'basket/totalPrice',
      isAuth: 'auth/isAuthenticated',
      user: 'profile/user',
      bonuses: 'basket/bonuses',
      usedBonuses: 'basket/setBonuses'


    }),
    totalAmount() {
      return this.order.reduce((accumulator, currentValue) => {

        let currentPrice

        if (currentValue.weights) {
          for (let i = 0; i < currentValue.weights.length; i++) {
            let attr = currentValue.weights[i]
            if (attr.sale.new_price !== null && currentValue.select_volume.weight === attr.weight) {

              this.newPrice = attr.sale.new_price
              this.newPriceSum = currentValue.select_count * attr.sale.new_price
            }
            if (attr.sale.new_price === null) {
              currentPrice = currentValue.select_price
            }
          }
        }

        if (currentValue.weights[0].sale.new_price !== null && currentValue.weights[0].sale.new_price !== 0) {
          return this.totalPrice = accumulator + (currentValue.weights[0].sale.new_price * currentValue.select_count)
        } else {
          return this.totalPrice = accumulator + (currentValue.select_price * currentValue.select_count)
        }


      }, 0);
    },
      getBeauticianDiscount() {
      let discount = this.beauticianDiscount = (this.totalAmount - this.newPriceSum) / 100 * 10
      this.setPercentDiscount(discount)
      return discount
    },
    amountWithDiscount() {
      if (this.isAuth) {
        let priceWithDiscount = (this.totalAmount - this.newPriceSum) - this.discount.your_discount + this.newPriceSum
        console.log(priceWithDiscount);
        if (this.certificateResponse) {
          console.log(1);
          return priceWithDiscount - this.getBeauticianDiscount - this.certificateResponse.data.amount - this.usedBonuses
        }
        if (this.usedBonuses) {
          console.log(2);
          return priceWithDiscount - this.getBeauticianDiscount - this.usedBonuses
        }
        if (this.user.professional_promo) {

          if (this.newPriceSum === this.totalAmount) {
            console.log(this.newPriceSum, 3);
            return this.totalAmount
          } else {
            return priceWithDiscount - this.getBeauticianDiscount
          }

        }
        if (this.discount.your_discount && this.newPrice) {
          console.log(4);
          return priceWithDiscount
        } else {
          console.log(5);
          return this.totalAmount - this.discount.total_discount
        }
      } else {
        console.log(6);
        return this.totalAmount
      }


    }


  },


}


import {mapMutations, mapGetters, mapActions} from "vuex";
import _ from "lodash";

export default {
  name: "basket-item",
  props: {
    item: {},
    index: {
      type: Number
    }
  },
  data() {
    return {
      selectCount: this.item.select_count,
      outOfStock: false

    }
  },
  created() {
    this.setVolume();
    //TODO: delete this after connect to product api
    // this.item.image = require('../../../../../../assets/img/card/upload0.jpg')
  },

  computed: {
    ...mapGetters({
      countBasketItems: 'basket/countBasketItems',
      isAuth: 'auth/isAuthenticated',
      basket: 'basket/basket',
    }),
    getCurrentPrice() {

      if (this.item.weights) {
        for (let i = 0; i < this.item.weights.length; i++) {
          let attr = this.item.weights[i]
          if (attr.sale.new_price && this.item.select_volume.weight === attr.weight) {
            return attr.sale.new_price
          }
          if (!attr.sale.new_price) {
            console.log(attr.price,'price');
            return attr.price
          }
        }
      }
      // if (this.item.weight) {
      //   if (this.item.weight.sale.new_price) {
      //     return this.item.weight.sale.new_price
      //   }
      //   return this.item.select_price
      // }

    }

  },
  methods: {
    ...mapMutations({
      remove: 'basket/REMOVE_ITEM_FROM_BASKET',
      increment: 'basket/INCREMENT_BASKET_LIST_COUNT',
      decrement: 'basket/DECREMENT_BASKET_LIST_COUNT',
      setCount: 'basket/SET_BASKET_LIST_COUNT',
      removeCertificate: 'order/SET_CERTIFICATE_CHECK'
    }),
    ...mapActions({
      getDiscount: 'basket/CHECK_DISCOUNT',
      getBonuses: 'basket/CHECK_BONUSES',

    }),
    sendRequest: _.debounce(function () {
      if (this.isAuth && this.basket.length !== 0) {


        let products = this.basket.map(product => {
          return {
            weight_id: product.select_volume.id,
            count: product.select_count
          }
        })

        this.getDiscount({products})
        this.getBonuses({products})

      }
    }, 500),
    setCost() {

    },
    setVolume() {
      let type;
      if (this.item.weights) {
        this.item.weights.forEach((currentValue) => {
          if (currentValue.price === this.item.select_price) {
            switch (currentValue.type) {
              case 'миллилитры':
                type = 'мл'
                break;
              case 'milliliters':
                type = 'ml'
                break;
              case 'граммы':
                type = 'гр'
                break;
              case 'grams':
                type = 'grams'
                break;
            }
            this.item.select_weightType = type
          }
        });
      }


    },
    removeProduct(item) {
      this.sendRequest()
      this.remove(item)
      this.removeCertificate()
    },
    changeCount(type, count = 1) {
      if (type === 'increment') {


        if (this.item.select_volume.count - 1 <= this.selectCount) {
          this.$refs.increment.setAttribute('disabled', true)
          this.outOfStock = true
        }


      }
      if (type === 'increment') {
        this.selectCount++
        this.increment(this.item)

      } else if (type === 'decrement') {
        this.$refs.increment.removeAttribute('disabled')
        this.outOfStock = false
        if (this.selectCount > 1) {
          this.selectCount--
          this.decrement(this.item)
        }
      } else {
        if (count) {
          this.setCount({
            ...this.item,
            select_count: parseInt(count)
          })
        }
      }
      this.sendRequest()
    },


  }
}

import {mapGetters, mapMutations, mapActions} from "vuex";


export default {
    name: 'basket-discount',
    data() {
        return {
            payload: {
                code: '',
            },
            certificateEmpty: false,
            errorTitle: '',
            totalDiscount: false,
            percentDiscount: 0,
            sumDiscount: 0,
            bonusesValue: ''
        }
    },
    computed: {
        ...mapGetters({
            showPopupDiscountCosmetologist: 'basket/showPopupDiscountCosmetologist',
            showPopupDiscountBonus: 'basket/showPopupDiscountCosmetologist',
            showPopupDiscountOneTime: 'basket/showPopupDiscountCosmetologist',
            isAuth: 'auth/isAuthenticated',
            certificateResponse: 'order/certificateStatus',
            isCertificateLoading: 'order/isCertificateLoading',
            discount: 'basket/discount',
            basket: 'basket/basket',
            getTotalSum: 'basket/totalPrice',
            bonuses: 'basket/bonuses',
            getPayBonusResp: 'basket/setBonuses'
        })
    },
    methods: {
        ...mapMutations({
            changePopupDiscountCosmetologist: 'basket/CHANGE_DISCOUNT_COSMETOLOGIST',
            changePopupDiscountBonus: 'basket/CHANGE_DISCOUNT_BONUS',
            changePopupDiscountOneTime: 'basket/CHANGE_DISCOUNT_ONE_TIME',
            removeCertificate: 'order/SET_CERTIFICATE_CHECK'
        }),
        ...mapActions({
            useCertificate: 'order/GET_CERTIFICATE_CHECK',
            getDiscount: 'basket/CHECK_DISCOUNT',
            getBonuses: 'basket/CHECK_BONUSES',
            payBonuses: 'basket/PAY_BONUSES'
        }),
        sendPayBonuses(event) {
            let products = this.basket.map(product => {
                return {
                    weight_id: product.select_volume.id,
                    count: product.select_count,
                }

            })
            this.payBonuses({products, bonus_sum: parseInt(this.bonusesValue)}).catch(() => {
                event.preventDefault()

            })
        }
        ,
        sendCertificate(event) {
            if (this.payload.code === '') {
                this.certificateEmpty = true
                this.errorTitle = this.$t('fillField')
                event.preventDefault()
            } else {
                this.useCertificate(this.payload).then(() => {
                    this.$toasted.success(this.$t('activateCertificate'));
                }).catch(() => {
                    this.certificateEmpty = true
                    this.$toasted.error(this.$t('errorCode'));
                    this.errorTitle = this.$t('errorCode')
                    event.preventDefault()
                })
                this.certificateEmpty = false
                this.payload.code = ''
            }

        },
        cancelCertificate() {
            this.removeCertificate(false)
            this.$toasted.success(this.$t('canceledCertificate'));
        }
    },
    created() {


    }
}

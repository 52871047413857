import item from './components/basket-item/index.vue'
import discount from './components/basket-discounts/index.vue'
import orderInfo from './components/order-info/index.vue'
import {mapGetters, mapMutations, mapActions} from "vuex";

export default {
    name: "basket",
    data() {
        return {

        }
    },
    components: {
        item,
        discount,
        orderInfo
    },
    computed: {
        ...mapGetters({
            basket: 'basket/basket',

        })
    },
    methods: {
        ...mapMutations({
            removeAll: 'basket/RESET_BASKET'
        }),
        ...mapActions({

        })
    },
    created() {

        console.log('basket',this.basket)
    }
}
